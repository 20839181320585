import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import i18n from '../../../i18n';

import ErrorIcon from '../animations/Error';

export default class HomeOffline extends React.PureComponent {
    static propTypes = {
        publishHome: PropTypes.func.isRequired,
        isPublishingHome: PropTypes.bool
    };

    static defaultProps = {
        isPublishingHome: false
    };

    render() {
        const btnSubmitPublishClass = classNames('btn btn-primary pull-right btn-ajax', {
            sending: this.props.isPublishingHome
        });
        return (
            <section className="popup-contact">
                <header className="modal-header">
                    <h1 className="modal-title">{i18n.t('exchange:contact.cant_contact')}</h1>
                </header>
                <div className="modal-body text-center vertical-center">
                    <div className="popup-icon">
                        <ErrorIcon />
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('exchange:contact.home_offline_text')
                        }}
                    />
                </div>
                <footer className="modal-footer">
                    <a href="#" className={btnSubmitPublishClass} onClick={this.props.publishHome}>
                        {i18n.t('home:publish_my_home')}
                    </a>
                </footer>
            </section>
        );
    }
}
