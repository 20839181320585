import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18n from '../../i18n';
import Loading from '../common/Loading';
import SavedSearches from '../../models/Alert';
import User from '../../models/User';
import { fetchSavedSearches } from '../../actions/savedSearches';

const PreferredDestinations = ({ user, currentUser, isLogged, destinations, fetchSavedSearches }) => {
    useEffect(() => {
        if (user) {
            fetchSavedSearches(user.id);
        }
    }, [user, fetchSavedSearches]);

    if (!user) {
        return (
            <>
                <div role="separator" className="divider" />
                <Loading />
            </>
        );
    }

    if (destinations.length > 0) {
        const places = Array.from(
            new Set(
                destinations
                    .filter((desti) => !desti.isOutdated())
                    .filter(
                        (desti) =>
                            typeof desti.get('params').place === 'string' &&
                            desti.get('params').place.length > 0
                    )
                    .sort(SavedSearches.sortByDate)
                    .map((dest) => dest.get('params').place.split(',')[0].trim())
            )
        );

        return (
            <>
                <div role="separator" className="divider" />
                <div className="preferred-destinations-info-content">
                    <i
                        className={
                            isLogged && currentUser && currentUser.get('is_he_collection')
                                ? 'icon-preferred_destination'
                                : 'icon-preferred-destinations'
                        }
                    />
                    <div>
                        <span
                            className="home-preferred-destinations"
                            dangerouslySetInnerHTML={{
                                __html:
                                    places.length <= 3
                                        ? i18n.t('user:user.preferred.destinations', {
                                              first_name: user.get('first_name'),
                                              places: places.join(', '),
                                              count: places.length
                                          })
                                        : i18n.t('user:user.preferred.destinations.andmore', {
                                              first_name: user.get('first_name'),
                                              places: places.slice(0, 3).join(', '),
                                              remaining: Math.max(places.length - 3, 0)
                                          })
                            }}
                        />{' '}
                        <a href={i18n.t('user:user.view.url', { id: user.id })} className="seemore">
                            {i18n.t('common:see_more')}
                        </a>
                    </div>
                </div>
            </>
        );
    }

    return null;
};

PreferredDestinations.propTypes = {
    user: PropTypes.instanceOf(User),
    currentUser: PropTypes.instanceOf(User),
    isLogged: PropTypes.bool,
    destinations: PropTypes.arrayOf(PropTypes.instanceOf(SavedSearches)).isRequired,
    fetchSavedSearches: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    user: state.loadedUsers[ownProps.userId],
    currentUser: state.user,
    isLogged: Boolean(state.auth.accessToken),
    destinations: state.savedSearches
});

const mapDispatchToProps = (dispatch) => ({
    fetchSavedSearches: bindActionCreators(fetchSavedSearches, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PreferredDestinations);
