import PropTypes from 'prop-types';
import React from 'react';

import i18n from '../../../i18n';
import HomeModel from '../../../models/Home';
import Utils from '../../../utils/utils';
import { trackHomeview, BUTTON_BLOCKER_EVENT } from '../../../utils/utils-ts';

import ErrorIcon from '../animations/Error';

export default class HomeNotFilled extends React.PureComponent {
    static propTypes = {
        home: PropTypes.instanceOf(HomeModel),
        abTest: PropTypes.bool
    };

    render() {
        const { abTest, home } = this.props;

        return (
            <section className="popup-contact">
                <header className="modal-header">
                    <h1 className="modal-title">
                        {i18n.t(`exchange:contact.${abTest ? 'cant_contact_ab_test' : 'cant_contact'}`)}
                    </h1>
                </header>
                <div className="modal-body text-center vertical-center">
                    <div className="popup-icon">
                        {abTest ? (
                            <img
                                src={`${Utils.getCloudfrontDomain()}/images/website/home/photoblocker_home_popup.png`}
                            />
                        ) : (
                            <ErrorIcon />
                        )}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: i18n.t(
                                `exchange:contact.${
                                    abTest
                                        ? home
                                            ? 'home_filling_text_ab_test'
                                            : 'home_filling_text_ab_test_noHome'
                                        : 'home_filling_text'
                                }`
                            )
                        }}
                    />
                </div>
                <footer className="modal-footer">
                    {home ? (
                        <a
                            href={i18n.t('home:home.edit.url', { id: home.id })}
                            onClick={(e) => trackHomeview(BUTTON_BLOCKER_EVENT, 'contact_complete_home', e)}
                            className={`btn btn-primary pull-right filling-home${
                                abTest ? '-photoblocker' : ''
                            }`}
                        >
                            {i18n.t('home:filling_my_home')}
                        </a>
                    ) : (
                        <a
                            href={i18n.t('home:home.create.url')}
                            className="btn btn-primary pull-right no-home"
                        >
                            {i18n.t('home:create_a_home')}
                        </a>
                    )}
                </footer>
            </section>
        );
    }
}
