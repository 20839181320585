import React from 'react';

import i18n from '../../../i18n';
import { trackHomeview, BUTTON_BLOCKER_EVENT } from '../../../utils/utils-ts';

import ErrorIcon from '../animations/Error';

export default class NoHome extends React.PureComponent {
    render() {
        return (
            <section className="popup-contact">
                <header className="modal-header">
                    <h1 className="modal-title">{i18n.t('exchange:contact.cant_contact')}</h1>
                </header>
                <div className="modal-body text-center vertical-center">
                    <div className="popup-icon">
                        <ErrorIcon />
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('exchange:contact.no_home_text')
                        }}
                    />
                </div>
                <footer className="modal-footer">
                    <a
                        href={i18n.t('home:home.create.url')}
                        onClick={(e) => trackHomeview(BUTTON_BLOCKER_EVENT, 'contact_create_home', e)}
                        className="btn btn-primary pull-right"
                    >
                        {i18n.t('home:create_a_home')}
                    </a>
                </footer>
            </section>
        );
    }
}
